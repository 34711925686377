import { render, staticRenderFns } from "./qxc.vue?vue&type=template&id=4a0bd0aa&scoped=true&"
var script = {}
import style0 from "./qxc.vue?vue&type=style&index=0&id=4a0bd0aa&scoped=true&lang=scss&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "4a0bd0aa",
  null
  
)

export default component.exports