<template>
    <div class="game_rule">
        <div class="content">
            <div class="bookmark_row">
                <div class="bookmark disable"  @click="changePage(0)">
                     <img class="bookmark_icon" src="@/img/icon/about-1.png">
                    关于我们
                </div>
                <div class="line"></div>
                <div class="bookmark disable" @click="changePage(1)">
                    <img class="bookmark_icon" src="@/img/icon/disclaimer-1.png">
                    免责声明
                </div>
                <div class="line"></div>
                <div class="bookmark">
                    <img class="bookmark_icon" src="@/img/icon/rules-2.png">
                    玩法规则
                </div>
            </div>
            <div class="text_box">
                <div class="option">
                    <div class="lottery" :class="gameRuleIndex==0?'checked':''" @click="changeRule(0)">
                        七星彩
                    </div>
                    <div class="line"></div>
                    <div class="lottery" :class="gameRuleIndex==1?'checked':''" @click="changeRule(1)">
                        排列五
                    </div>
                    <div class="line"></div>
                    <div class="lottery" :class="gameRuleIndex==2?'checked':''" @click="changeRule(2)">
                        排列三
                    </div>
                    <div class="line"></div>
                    <div class="lottery" :class="gameRuleIndex==3?'checked':''" @click="changeRule(3)">
                        香港六合彩
                    </div>
                    
                    <!--
                    <div class="line"></div>
                    <div class="lottery" :class="gameRuleIndex==4?'checked':''" @click="changeRule(4)">
                        新加坡六合彩
                    </div>
                    <div class="line"></div>
                    <div class="lottery" :class="gameRuleIndex==5?'checked':''" @click="changeRule(5)">
                        美国强力球
                    </div>
                    <div class="line"></div>
                    <div class="lottery" :class="gameRuleIndex==6?'checked':''" @click="changeRule(6)">
                        美国超级百万
                    </div>
                    -->
                </div>
                <QXC v-if="gameRuleIndex==0"/>
                <Pl5 v-if="gameRuleIndex==1"/>
                <Pl3 v-if="gameRuleIndex==2"/>                
                <HKlhc v-if="gameRuleIndex==3"/>
                <Sgplhc v-if="gameRuleIndex==4"/>
                <USPowerBall v-if="gameRuleIndex==5"/>
                <USMegaMillions v-if="gameRuleIndex==6"/>
            </div>
        </div>
    </div>
</template>

<script>
    import QXC from '@/components/game_rule/qxc.vue'
    import Pl5 from '@/components/game_rule/pl5.vue'
    import Pl3 from '@/components/game_rule/pl3.vue'
    import HKlhc from '@/components/game_rule/hklhc.vue'
    import Sgplhc from '@/components/game_rule/sgplhc.vue'
    import USPowerBall from '@/components/game_rule/usPowerBall.vue'
    import USMegaMillions from '@/components/game_rule/usMegaMillions.vue'
    
    export default {
        name: 'GameRule',
        components: {
            QXC,
            Pl5,
            Pl3,
            HKlhc,
            Sgplhc,
            USPowerBall,
            USMegaMillions,
        },
        data() {
            return {
                pageUrl: [
                    "about", "disclaimer", "gamerule"
                ],
                gameRuleIndex: 0,
            }
        },
        computed: {
        },
        methods: {
            changePage (i) {
                this.$router.push(this.pageUrl[i]);
            },
            changeRule (i) {
                this.gameRuleIndex = i;
            },
        },
        mounted() {
        }
    }
</script>

<style scoped lang="scss">
    .game_rule{
        .content {
            width: 1440px;
            height: auto;
            margin: auto;
            margin-top: 0.5rem;
            .bookmark_row{
                display: flex;
                justify-content: left;
                align-items: center;
                display: -webkit-flex;
                -webkit-align-items: center;
                margin-bottom: 40px;
                .bookmark{
                    z-index: 10;
                    cursor: pointer;
                    font-size: 17px;
                    font-weight: bold;
           
                    text-align: center;
                    color: #ff6819;
                    cursor: pointer;
                    .bookmark_icon{
                      width: 22px;
                      vertical-align: text-bottom;
                    }
                }
             
                .disable{
                    color: #0f2957;
                }
                .line{
                    height: 14px;
                    width: 1px;
                    background-color: #d0d1d3;
                    margin: 0 16px;
                }
            }
            .text_box{
                box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
                background-color: #ffffff;
                margin: auto;
                padding: 0.5rem 0.8rem 0.8rem 0.8rem;
                box-sizing: border-box;
                border-radius: 20px;
                box-shadow: 0 3px 8px 0 rgba(2, 18, 52, 0.2);
                border: solid 3px #e0d6a7; 
                background: #fff;
                width: 100%;
                margin-bottom: 0.8rem;
                .option{
                    display: flex;
                    margin-bottom: 0.8rem;
                    .line{
                        height: 14px;
                        width: 1px;
                        background-color: #d0d1d3;
                        margin: 0 16px;
                    }
                    .lottery{
                        font-size: 17px;
                        font-weight: 600;
                        text-align: left;
                        color: #0f2957;
                        cursor: pointer;
                    }
                    .lottery.checked{
                        color: #ff6819;
                    }
                }
                .t2{
                    text-indent: 0.2rem;
                }
                h1{
                    
                    font-size: 20px;
                    font-weight: bold;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.76;
                    letter-spacing: normal;
                    text-align: left;
                    color: #383838; 
                }
                p{
                    
                    font-size: 17px;
                    font-weight: 500;
                    font-stretch: normal;
                    font-style: normal;
                    line-height: 1.76;
                    letter-spacing: normal;
                    text-align: left;
                    color: #646363; 
                }
            }
        }
    }    
</style>